<template>
  <div class="ticketIndex hyundai">
    <!-- 시즌준비중 서브 상단 -->
<!--    <div class="subTop hyundai noGame">-->
<!--      <div class="top">-->
<!--        <div class="container">-->
<!--          <div class="breadCrumb">-->
<!--            홈 <span class="arr">&#8250;</span>-->
<!--            <router-link to="/ticket">TICKET</router-link>-->
<!--          </div>-->
<!--          <div class="topTitle">-->
<!--            <p class="cate">티켓</p>-->
<!--            <p class="subCate">티켓 메인</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- //시즌준비중 서브 상단 -->
    <div class="subTop"
    :class="match.length === 0 ? 'noGame' : ''"> <!-- 다음 홈경기가 없을 때 .noGame -->
      <!-- 상단 -->
      <div class="top">
        <div class="container">
          <div class="breadCrumb">
            홈 <span class="arr">&#8250;</span>
            <router-link to="/ticket">TICKET</router-link>
          </div>
          <div class="topTitle">
            <p class="cate">티켓</p>
            <p class="subCate">티켓 메인</p>
          </div>
          <!-- 다음 홈경기가 있을 때 -->
          <div>
            <div class="nextGame" v-if="mainMatch">
              <span class="league">{{mainMatch.game_league_name}}</span>
              <div class="game emblem">
                <div class="team left">
                  <img :src="mainMatch.homeLogo" alt="">
                  {{mainMatch.home_team_name}}
                </div>
                <div class="center">
                  VS
<!--                  <div class="round"><span>8강</span></div>-->
                </div>
                <div class="team right">
                  <img :src="mainMatch.awayLogo" alt="">
                  {{mainMatch.away_team_name}}
                </div>
              </div>
              <div class="info">
                <div class="time">{{mainMatch.game_settime}}</div>
                <div class="date">{{mainMatch.game_matchDate}} ({{mainMatch.yoil}})</div>
                <Countdown
                  :center="true"
                  :label="true"
                  :labelColor="'rgba(255,255,255,0.7)'"
                  :endDate="mainMatch.game_setCountDown" />
                <div class="stadium">{{mainMatch.field_name}}</div>
              </div>
            </div>
            <div class="btns" v-if="match.length !== 0">
              <button class="btn yellow large btnReservation" @click="purchase">티켓예매</button>
              <router-link :to="{name: 'fanzoneEvent'}" class="btn large btnEvent" >이벤트 안내</router-link>
            </div>
          </div>
          <!-- //다음 홈경기가 있을 때 -->
          <!-- 다음 홈경기가 없을 때 -->
<!--          <div class="topTitle">-->
<!--            <p class="cate">TICKET</p>-->
<!--            <p class="subCate">티켓 메인</p>-->
<!--          </div>-->
          <!-- //다음 홈경기가 없을 때 -->
        </div>
      </div>
      <!-- //상단 -->
    </div>
    <!-- 다음홈경기 -->
    <div class="nextMatch matchPage" v-if="match.length !== 0">
      <div class="container">
        <div class="sectionTitle sectionTitle2">다음 홈경기</div>
        <!-- 리스트 -->
        <div class="matchList">
          <!-- 매치 -->
          <div class="item" v-for="(item, i) in match" :key="i">
            <div class="info">
              <div class="league" :data-league="item.game_league"></div>
              <div class="time">D-{{item.d_day}}</div>
              <div class="date">{{item.game_matchDate}} ({{item.yoil}})</div>
              <div class="stadium">{{item.field_name}}</div>
            </div>
            <div class="game emblem">
              <div class="team left">
                <img :src="item.homeLogo" class="emblem" alt="">
                <span class="name">{{item.home_team_name}}</span>
              </div>
              <div class="center">
                vs
              </div>
              <div class="team right">
                <img :src="item.awayLogo" class="emblem" alt="">
                <span class="name">{{item.away_team_name}}</span>
              </div>
            </div>
            <div class="btns mobileFlex">
              <div class="row">
                <button class="btn" @click="purchase">{{item.isBtn ? item.btnText : '티켓예매'}}</button>
              </div>
            </div>
          </div>
          <!-- //매치 -->
        </div>
        <!-- //리스트 -->
      </div>
      <div class="container">
        <div class="term" v-if="state">
          <span>※ 경기일정은 우천, 천재지변, 불가항력에 의한 사유로 취소, 연기, 중단될 수 있습니다.</span>
        </div>
      </div>
      <div class="container" v-if="state">
        <img src="@/assets/img/ticketPolicy_2024.jpeg" alt=""/>
      </div>
    </div>
    <!-- //다음홈경기 -->
    <!-- 이벤트 -->
    <div class="indexBox" v-if="state">
      <div class="container">
        <div class="flexBox ticketEvent">
          <!-- 티켓이벤트 -->
          <div class="flexInner">
            <div class="sectionTitle">티켓 이벤트 <router-link to="/ticket/service/event" class="viewmore">더보기</router-link></div>
            <div class="eventSlider">
              <vueper-slides
                :slide-ratio="352/580"
                :infinite="true"
                :duration="5000"
                :arrows="false"
                :touchable="false"
                autoplay
                class="no-shadow">
                <vueper-slide
                  v-for="(event, i) in 3"
                  :key="i">
                  <template #content>
                    <div class="banner"><img src="https://i.imgur.com/0LX7TUt.png" alt=""></div>
                    <div class="status"><span>진행중</span></div>
                    <p class="title">전북현대 2023시즌 개막전 하이파이브 시즌권 대상 참가자 신청</p>
                    <p class="desc">2022년 1월 10일 10:00 ~ 2022년 2월 10일 19:00</p>
                  </template>
                </vueper-slide>
              </vueper-slides>
              <div class="noData">
                진행중인 이벤트가 없습니다
              </div>
            </div>
          </div>
          <!-- //티켓이벤트 -->
          <!-- 홈경기이벤트 -->
          <div class="flexInner">
            <div class="sectionTitle">홈경기 이벤트 <router-link to="/ticket/service/event" class="viewmore">더보기</router-link></div>
            <div class="eventSlider">
              <vueper-slides
              :slide-ratio="352/580"
                :infinite="true"
                :duration="5000"
                :arrows="false"
                :touchable="false"
                autoplay
                class="no-shadow">
                <vueper-slide
                  v-for="(event, i) in 3"
                  :key="i">
                  <template #content>
                    <div class="banner"><img src="https://i.imgur.com/r5ShBO0.png" alt=""></div>
                    <div class="status"><span>진행중</span></div>
                    <p class="title">전북현대 2023시즌 개막전 하이파이브 시즌권 대상 참가자 신청</p>
                    <p class="desc">2022년 1월 10일 10:00 ~ 2022년 2월 10일 19:00</p>
                  </template>
                </vueper-slide>
              </vueper-slides>
            </div>
          </div>
          <!-- //홈경기이벤트 -->
        </div>
      </div>
    </div>
    <!-- //이벤트 -->
    <!-- 공지사항 -->
    <div class="indexBox" v-if="state">
      <div class="container">
        <div class="sectionTitle">티켓 공지사항 <router-link to="/ticket/service/notice" class="viewmore">더보기</router-link></div>
        <div class="boardList">
          <div class="boardItem">
            <router-link to="/ticket/service/notice">
              <div class="title">
                <p class="subject">김상식 감독, "팬들께 우승 안겨 드려 기쁘다 김상식 감독, "팬들께 우승 안겨 드려 기쁘다 김상식 감독, "팬들께 우승 안겨 드려 기쁘다 김상식 감독, "팬들께 우승 안겨 드려 기쁘다"</p>
                <div class="info">
                  <span class="date">2022.07.09</span>
                  <span class="view">{{comma(10415)}}</span>
                </div>
              </div>
              <div class="desc">전북 현대는 30일 전주월드컵경기장에서 열린 2022 하나원큐 FA컵 결승 2차전서 FC 서울에 3-1로 승리했다. 1차전서 2-2 무승부를 기록했던 전북은 이날 승리로 1, 2차전 합께 5-3으로 FA컵 정상에 올랐다. 전북은 2000, 2003, 2005, 2020년에 이어 올 해 전북 현대는 30일 전주월드컵경기장에서 열린 2022 하나원큐 FA컵 결승 2차전서 FC 서울에 3-1로 승리했다. 1차전서 2-2 무승부를 기록했던 전북은 이날 승리로 1, 2차전 합께 5-3으로 FA컵 정상에 올랐다. 전북은 2000, 2003, 2005, 2020년에 이어 올 해</div>
            </router-link>
          </div>
          <div class="boardItem">
            <router-link to="/ticket/service/notice">
              <div class="title">
                <p class="subject">김상식 감독, "팬들께 우승 안겨 드려 기쁘다"</p>
                <div class="info">
                  <span class="date">2022.07.09</span>
                  <span class="view">{{comma(10415)}}</span>
                </div>
              </div>
              <div class="desc">전북 현대는 30일 전주월드컵경기장에서 열린 2022 하나원큐 FA컵 결승 2차전서 FC 서울에 3-1로 승리했다. 1차전서 2-2 무승부를 기록했던 전북은 이날 승리로 1, 2차전 합께 5-3으로 FA컵 정상에 올랐다. 전북은 2000, 2003, 2005, 2020년에 이어 올 해 전북 현대는 30일 전주월드컵경기장에서 열린 2022 하나원큐 FA컵 결승 2차전서 FC 서울에 3-1로 승리했다. 1차전서 2-2 무승부를 기록했던 전북은 이날 승리로 1, 2차전 합께 5-3으로 FA컵 정상에 올랐다. 전북은 2000, 2003, 2005, 2020년에 이어 올 해</div>
            </router-link>
          </div>
        </div>
        <div class="noData">
          공지사항이 없습니다
        </div>
      </div>
    </div>
    <!-- //공지사항 -->
    <!-- 좌석 배치도 -->
    <div class="indexBox stadiumSeat" v-if="!state">
      <div class="container">
        <div class="sectionTitle">좌석 배치도</div>
        <Seats />
      </div>
    </div>
    <!-- //좌석 배치도 -->
    <!-- 좌석별 소개 -->
    <div class="indexBox" v-if="state">
      <div class="container">
        <div class="sectionTitle">좌석별 소개</div>
        <div class="seatIntro">
          <div
            v-for="seat in seatIntro"
            :key="seat.id"
            class="seat">
            <div class="img"><img :src="require(`@/assets/img/img_seat_${seat.id}.png`)" alt=""></img></div>
            <p class="name">{{seat.name}}</p>
            <div class="desc">{{seat.desc}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- //좌석별 소개 -->
    <!-- 좌석 배치도 -->
    <div class="indexBox" v-if="!state">
      <div class="container">
        <div class="sectionTitle">티켓 정책</div>
        <img src="@/assets/img/ticketPolicy_2024_1.jpg" alt=""/>
        <img src="@/assets/img/ticketPolicy_2024_2.jpg" alt=""/>
        <div v-if="state">
          <TicketPolicy />
          <div class="termbox">
            <span>티켓 정책 안내사항</span>
            <div class="ulbox">
              <ul>
                <li>어린이 : 24개월 이상 ~ 초등6학년 (어린이 입장권 적용)</li>
                <li>유아 : 24개월 미만 → 무료 입장 가능 (좌석 점유 불가)</li>
              </ul>
            </div>
          </div>
          <div class="termbox">
            <span>환불 정책</span>
            <div class="ulbox">
              <ul>
                <li>경기 취소 : 전액 환불</li>
                <li>경기 중단, 연기, 변경 : 재경기 입장권 교환 또는 전액 환불</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- //좌석 배치도 -->
    <!-- 시즌 준비중 -->
<!--    <div class="indexBox waitingSeason">-->
<!--      <div class="container">-->
<!--        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>-->
<!--      </div>-->
<!--    </div>-->
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import Countdown from '@/components/Util/Countdown'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import regExp from '@/common/regExp'
import Seats from '@/components/Util/Seats'
import TicketPolicy from './TicketPolicy'
import http from "@/api"
import dayjs from "dayjs"
import imgPath from "@/library/imgPath"
import {reservePopup} from "@/library/ticketlink"
import router from "@/router"

export default {
  name: 'TicketIndex',
  components: {
    Countdown,
    VueperSlides,
    VueperSlide,
    Seats,
    TicketPolicy,
  },
  computed:{
    isLogin () {
      return this.$store.getters['isLogin']
    },
    mainMatch () {
      return this.match[0]
    }
  },
  mounted() {
    if (localStorage.getItem('favorite')) {
      const favorite = JSON.parse(localStorage.getItem('favorite'))
      if (favorite === null) {
        if (this.isLogin !== false) {
          if (window.confirm('필수 추가 정보를 입력하지 않아, 티켓 예매 서비스를 이용하실 수 없습니다.\n' +
              '필수 추가 정보를 기입하시겠습니까? ')) {
            return this.$router.push('/member/myinfo')
          } else {
            return this.$router.go(-1)
          }
        }
      }
    }
    this.getEncryptedId()
  },
  created () {
    this.datetime = '2022-12-31T15:00:00.000Z'
    this.init()
    http.get('/nav/event').then(res => {
      const eventList = res.data.data.list
        const nowTime = dayjs()
        const result = eventList.filter(row => {
          return nowTime.isAfter(row.end_apply) === false
        })
        result.length > 0 ? this.isEvent = true : this.isEvent = false
    })
  },
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
      datetime: null,
      match: [],
      seatIntro: [
        { id: 'wvalue', name: 'W존 벨류', desc: '다수의 팬들과 함께 관전이 가능한 서측 좌석, 선수들 및 코칭스텝을 관찰할 수 있는 좌석, 햇빛 탈피 그늘 좌석' },
        { id: 'wbench', name: 'W벤치존(H)', desc: '가장 지근거리에서 선수들을 관찰할 수 있는 서측 좌석, 지정 좌석제, 햇빛 탈피 그늘 좌석' },
        { id: 'wedge', name: 'W존 엣지', desc: '가장 지근거리에서 선수들을 관찰할 수 있는 서측 좌석, 지정 좌석제, 햇빛 탈피 그늘 좌석' },
        { id: 'sensuous', name: '센슈어스존', desc: '경기장 최상의 위치에서 홈경기 관전이 가능한 단상석 하단 좌석, 구단 우승시 선수들을 관전할 수 있는 좌석, 편안한 지정 좌석제' }
      ],
      encrypted_id: '',
      eventList: [],
      isEvent: true,
    }
  },
  methods: {
    async init () {
      const match = await http.get('/ticket/next_match')
      this.match = match.data.body || []
      const today = dayjs().format('YYYY-MM-DD')
      this.match.map(el => {
        el.game_settime = el.game_time.substring(0, 5)
        el.game_matchDate = dayjs(el.game_date).format("YYYY.MM.DD")
        el.ticket_openDate = dayjs(el.game_date).subtract(5, 'd').format("MM.DD")
        el.ticket_setOpenDate = dayjs(el.game_date).subtract(5, 'd').hour(12).minute(0).second(0);
        el.game_setCountDown = `${el.game_date} ${el.game_settime}`
        el.game_countDown = dayjs(el.game_setCountDown).format("YYYY.MM.DD HH:mm:ss")
        el.expired_at = dayjs(el.game_date)
        el.d_day = Math.floor(el.expired_at.diff(today, "day", true))
        el.homeLogo = imgPath.setTeamLogo(el.home_team)
        el.awayLogo = imgPath.setTeamLogo(el.away_team)
        el.btnText = `${el.ticket_openDate} 12:00 티켓오픈`
        switch (el.meet_name) {
          case 'K League 1':
            el.game_league = 'kleague'
            el.game_league_name = 'K LEAGUE'
            break
          case 'ACL':
            el.game_league = 'afc'
            el.game_league_name = 'ACL'
            break
          case 'FA CUP':
            el.game_league = 'facup'
            el.game_league_name = 'FA CUP'
            break
        }
        if (dayjs() < el.ticket_setOpenDate) {
          el.isBtn = true
        } else {
          el.isBtn = false
        }
      })
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    getEncryptedId() {
      if(this.$store.state.user.encrypted_id !== null) {
        this.encrypted_id = this.$store.state.user.encrypted_id
      } else {
        this.encrypted_id = ''
      }
    },
    purchase() {
        this.getEncryptedId()
        if(this.encrypted_id !== '') {
          const url = reservePopup(this.encrypted_id, "pc");
          window.gtag('event','btn_구매하기',{'event_category':'티켓예매', 'event_label':this.$store.state.user.id})
          window.open(url,'tl', 'width=1010, height=890, left=100, top=100, scrollbars=yes')
        } else {
          alert("통합회원 로그인 후 이용해주세요.")
          const redirectUrl = router.currentRoute.path;
          router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
        }
    }
  }
}
</script>
<style lang="scss" scoped>
.termbox{
  margin-top: 30px;
  font-weight: 700;
  font-size: 16px;
}
.term{
  margin-bottom: 10px;
  font-size: 14px;
  color: #777777;
  font-weight: 700;
}
.ulbox{
  margin-left: 20px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #777777;
  li{
    list-style: disc;
  }
}
</style>
